import React, { Fragment, useState } from "react";
import { IconFilter, IconPlus } from "../../../Common/Components/Icons/Icons";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { IconCancelCircle, IconCheck } from "Common/Icons";

interface IProps {
  getAllTokens: any;
  addNewToken: any;
  selectedPort: any;
  applyAll: any;
}
const PORTS = [
  "ITS TERMINAL",
  "GCT NY",
  "VIGNIT",
  "VIGNIT_EMPTIES",
  "CP",
  "CN",
  "TRAPAC",
  "HLT",
  "EMODAL LA",
  "EMODAL NY",
  "EVERGLADES",
  "EVERPORT_TERMINAL",
  "PCT_EVERPORT",
  "EVERPORT_OAK",
  "BAYPORT TERMINAL",
  "BARBOURS CUT TERMINALS",
];

const TokenFilterRow = (props: IProps): React.ReactElement => {
  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <div className="position-relative">
            <SelectFieldWithoutControl
              className="w-200 mr-2"
              classNamePrefix="select"
              isLoading={false}
              isClearable={true}
              isSearchable={true}
              name="Port"
              size="small"
              placeholder="Select Port"
              // value={status}
              onChange={props.getAllTokens}
              options={PORTS.map((val: string) => ({
                label: val,
                value: val,
              }))}
            />
          </div>
        </div>

        <div className="ml-auto d-flex">
          {props.selectedPort &&
            ["ITS TERMINAL", "VIGNIT", "HLT", "EVERGLADES", "GCT NY", "TRAPAC"].includes(
              props.selectedPort
            ) && (
              <button className="btn btn-outline-light mr-2" onClick={() => props.applyAll()}>
                <IconCheck className="mr-2" />
                Apply All
              </button>
            )}
          <button className="btn btn-primary " onClick={() => props.addNewToken()}>
            <IconPlus className="mr-2" />
            Add NEW
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TokenFilterRow;
