import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { FiDownload } from "react-icons/fi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "./styles.css";

const AppointmentDailyReport = () => {
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({
    booked: false,
    summary: false,
    exception: false,
    potentialPickup: false,
    potentialEmpty: false,
    cancelledPickup: false,
    cancelledReturn: false,
    returnSummary: false,
  });
  const location = useLocation();
  const navigate = useNavigate();

  const getReportTitle = () => {
    switch (location.pathname) {
      case "/reports/booked-report-summary":
        return "Booked Report Summary";
      case "/reports/appointment-summary-report":
        return "Appointment Summary Report";
      case "/reports/exception-report":
        return "Exception Report";
      case "/reports/potential-pickup":
        return "Potential Bookings Pickup Report";
      case "/reports/potential-empty":
        return "Potential Bookings Empty Report";
      case "/reports/cancelled-pickup":
        return "Cancelled Pickup Report";
      case "/reports/cancelled-return":
        return "Cancelled Return Report";
      default:
        return "Appointment Daily Report";
    }
  };

  const downloadReport = async (
    endpoint: string,
    path: string,
    fileName: string,
    reportType:
      | "booked"
      | "summary"
      | "exception"
      | "potentialPickup"
      | "potentialEmpty"
      | "cancelledPickup"
      | "cancelledReturn"
      | "returnSummary"
  ) => {
    try {
      setIsLoading((prev) => ({ ...prev, [reportType]: true }));
      console.log(process.env.REACT_APP_REPORT_SERVER_URL);
      const baseUrl =
        process.env.REACT_APP_REPORT_SERVER_URL || "https://reports.trackos.portpro.io";
      const response = await axios.get(`${baseUrl}${endpoint}`);

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      if (!reportType.includes("potential")) {
        navigate(path);
      }
    } catch (error) {
      console.error("Error downloading report:", error);
    } finally {
      setIsLoading((prev) => ({ ...prev, [reportType]: false }));
    }
  };

  const renderButtonContent = (loadingState: boolean, text: string) => (
    <>
      {loadingState ? (
        <AiOutlineLoading3Quarters className="loading-spinner" />
      ) : (
        <FiDownload className="download-icon" />
      )}
      {loadingState ? "Downloading..." : text}
    </>
  );

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="reports" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15">
              <h4 className="mb-0">{getReportTitle()}</h4>
            </div>
            <div className="report-buttons">
              <div className="report-section">
                <h5>Summary Reports</h5>
                <div className="button-group">
                  <button
                    onClick={() =>
                      downloadReport(
                        "/generate-summary-report-pickup",
                        "/reports/appointment-summary-report",
                        "summary-report",
                        "summary"
                      )
                    }
                    disabled={isLoading.summary}
                  >
                    {renderButtonContent(isLoading.summary, "Download Pickup Summary Report")}
                  </button>
                  <button
                    onClick={() =>
                      downloadReport(
                        "/generate-summary-report-return",
                        "/reports/appointment-summary-report",
                        "return-summary-report",
                        "returnSummary"
                      )
                    }
                    disabled={isLoading.returnSummary}
                  >
                    {renderButtonContent(isLoading.returnSummary, "Download Return Summary Report")}
                  </button>
                </div>
              </div>
              <div className="report-section">
                <h5>Potential Reports</h5>
                <div className="button-group">
                  <button
                    onClick={() =>
                      downloadReport(
                        "/get-potential-bookings-pickup",
                        "/reports/potential-pickup",
                        "potential-pickup-report",
                        "potentialPickup"
                      )
                    }
                    disabled={isLoading.potentialPickup}
                  >
                    {renderButtonContent(
                      isLoading.potentialPickup,
                      "Download Potential Bookings Pickup"
                    )}
                  </button>
                  <button
                    onClick={() =>
                      downloadReport(
                        "/get-potential-bookings-return",
                        "/reports/potential-empty",
                        "potential-empty-report",
                        "potentialEmpty"
                      )
                    }
                    disabled={isLoading.potentialEmpty}
                  >
                    {renderButtonContent(
                      isLoading.potentialEmpty,
                      "Download Potential Bookings Return"
                    )}
                  </button>
                </div>
              </div>
              <div className="report-section">
                <h5>Cancelled Reports</h5>
                <div className="button-group">
                  <button
                    onClick={() =>
                      downloadReport(
                        "/generate-cancelled-pickup",
                        "/reports/cancelled-pickup",
                        "cancelled-pickup-report",
                        "cancelledPickup"
                      )
                    }
                    disabled={isLoading.cancelledPickup}
                  >
                    {renderButtonContent(
                      isLoading.cancelledPickup,
                      "Download Cancelled Pickup Report"
                    )}
                  </button>
                  <button
                    onClick={() =>
                      downloadReport(
                        "/generate-cancelled-return",
                        "/reports/cancelled-return",
                        "cancelled-return-report",
                        "cancelledReturn"
                      )
                    }
                    disabled={isLoading.cancelledReturn}
                  >
                    {renderButtonContent(
                      isLoading.cancelledReturn,
                      "Download Cancelled Return Report"
                    )}
                  </button>
                </div>
              </div>
              <div className="report-section">
                <h5>Other Reports</h5>
                <div className="button-group">
                  <button
                    onClick={() =>
                      downloadReport(
                        "/get-booked-appointments",
                        "/reports/booked-report-summary",
                        "booked-report",
                        "booked"
                      )
                    }
                    disabled={isLoading.booked}
                  >
                    {renderButtonContent(isLoading.booked, "Download Successful Bookings Report")}
                  </button>
                  <button
                    onClick={() =>
                      downloadReport(
                        "/generate-exception-report",
                        "/reports/exception-report",
                        "exception-report",
                        "exception"
                      )
                    }
                    disabled={isLoading.exception}
                  >
                    {renderButtonContent(isLoading.exception, "Download Exception Report")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDailyReport;
