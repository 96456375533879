import axios from "axios";
import { api } from "config";
import _ from "lodash";
import { prodApi } from "pages/DrayosSummaryReport/action";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

const updateScacApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    xapikey: process.env.REACT_APP_XAPI || "",
    "Content-Type": "application/json",
  },
});

export const getfilterContainer = (data: any, params: any) => {
  delete data.reset;
  let postData = removeNullEmpty(data);
  let url = "api/dashboard/filterContainer";
  url = params ? url + "?" + objToQueryParams(params) : url;

  return {
    type: "DASHBOARD_FILTER_CONTAINERS",
    payload: api.post(url, postData),
  };
};

export const setDashboardAvailableParams = (filterSearchPost?: any, searchParams?: any) => {
  delete filterSearchPost.reset;
  return {
    type: "SET_DASHBOARD_PARAMS",
    payload: { filterSearchPost, searchParams },
  };
};

export const getAllUsers = (data?: any, params?: any) => {
  delete data.reset;
  let postData = removeNullEmpty(data);
  let url = "api/dashboard/getAllUsers";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return {
    type: "GET_ALL_USERS",
    payload: api.post(url, postData),
  };
};

export const createSuperAdmin = async (data: { email: string; password: string }) => {
  return {
    type: "CREATE_SUPERADMIN",
    payload: api.post("api/dashboard/createSuperAdmin", data),
  };
};

export const deleteSuperAdmin = async (email: string) => {
  return {
    type: "DELETE_SUPERADMIN",
    payload: api.delete(`api/dashboard/deleteSuperAdmin/${email}`),
  };
};

export const setSuperAdminsParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "SET_SUPERADMINS_PARAMS",
    payload: { searchPost, searchParams },
  };
};

export const resetSuperAdminsParams = () => {
  return {
    type: "RESET_SUPERADMINS_PARAMS",
  };
};

export const getNotUpdatedContainers = async (params?: any) => {
  delete params.reset;
  let filterParams = removeNullEmpty(params);
  let url = "api/container/getNotUpdatedContainers";
  url = filterParams ? url + "?" + objToQueryParams(filterParams) : url;
  return {
    type: "NOTUPDATEDCONTAINERS_LIST",
    payload: api.get(url),
  };
};

export const setNotUpdatedContainersParams = (searchParams?: any) => {
  delete searchParams.reset;
  return {
    type: "SET_NOTUPDATEDCONTAINERS_PARAMS",
    payload: { searchParams },
  };
};
export const resetNotUpdatedContainersParams = () => {
  return {
    type: "RESET_NOTUPDATEDCONTAINERS_PARAMS",
  };
};

export const updateContainerById = (data: any) => {
  return {
    type: "UPDATE_CONTAINER",
    payload: api.put("api/container/updateContainerById", data),
  };
};

export const resetAvailableParams = () => {
  return {
    type: "RESET_AVAILABLEPARAMS",
  };
};

export const cancelSubscription = (data: { email: string; subscriptionId: string }) => {
  return {
    type: "CANCEL_SUBSCRIPTIONS",
    payload: api.put(`api/auth/cancelSubscription`, data),
  };
};

export const subscriptionStatus = (data: {
  isPaid: boolean;
  userId: string;
  drayosTrackFeatures: Array<string>;
}) => {
  // console.log("new subscription action", data);
  return {
    type: "SUBSCRIPTION_STATUS",
    payload: api.post(`api/drayos/updatePayment`, data),
  };
};

export const setUserInfoAvailableParams = (userSearchPost?: any, userSearchParams?: any) => {
  return {
    type: "SET_USER_PARAMS",
    payload: { userSearchPost, userSearchParams },
  };
};

// UPdate voyage
export const updateVoyageById = (data: any) => {
  return {
    type: "UPDATE_VOYAGE",
    payload: api.put("api/voyage/update", data),
  };
};

export const getDrayoCarrierDetail = () => {
  // let url = "v1/reports/getDrayosTrackCarriers";
  const url = "api/dashboard/get-track-carriers";
  return {
    type: "DRAYOSCARRIER_DETAIL",
    payload: api.get(url),
  };
};

export const updateScacCode = (data: {
  userId: string;
  carrierScac: string;
  otherScac: string[];
}) => {
  const url = `api/drayos/update-scac`;
  return {
    type: "UPDATESCAC_CODE",
    payload: updateScacApi.post(url, data),
  };
};

export const updateGpaAuthorization = (data: { carrierId: string; toAuthorize: boolean }) => {
  const url = "api/gpa/authorize-gpa";
  return {
    type: "UPDATEGPA_AUTHORIZATION",
    payload: api.post(url, data),
  };
};

export const updateLbctCredentials = (data: {
  userId: string;
  api_key: string;
  scac: string;
}) => {
  const url = `api/drayos/lbct-credential`;
  return {
    type: "UPDATELBCT_API",
    payload: updateScacApi.post(url, data),
  };
};
