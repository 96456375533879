import axios from "axios";

const apiKey = process.env.REACT_APP_NODE_EMPTIES_XAPI ?? "";

const createHeaders = (): Record<string, string> => ({
  "Access-Control-Allow-Origin": "*",
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
  "X-API-KEY": apiKey || "",
});

const emptiesApi = () => {
  const baseURL = process.env.REACT_APP_NODE_EMPTIES_SVC_DEV ?? "";

  if (!baseURL) {
    throw new Error("Missing REACT_APP_NODE_EMPTIES_SVC_DEV environment variable");
  }

  if (!apiKey) {
    throw new Error("Missing REACT_APP_NODE_EMPTIES_XAPI environment variable");
  }

  const instance = axios.create({
    baseURL,
    headers: createHeaders(),
  });

  instance.interceptors.request.use((config) => {
    return config;
  });

  return instance;
};

export default emptiesApi;
