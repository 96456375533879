import { IconCancelCircle } from "Common/Icons";
import React, { useState } from "react";
import { Button, Form, FormControl, FormLabel, Modal } from "react-bootstrap";
import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import { updateLbctCredentials } from "pages/Dashboard/action"; 
import { getAllUsers } from "pages/Dashboard/action";

const UpdateLbctPop = (props: any) => {
    const { state, myDispatch } = useAppContext();
    const { cLoading } = state.dashboardStates;
    const { userSearchPost, userSearchParams, updateScacResponse } = state.dashboardStates;

    const {
        showUpdateCredentialModal,
        handleClose,
        selectedUser,
    } = props;
    
    const [lbctApiKey, setLbctApiKey] = useState(selectedUser?.api_key || "");
    const [lbctScac, setLbctScac] = useState(selectedUser?.scac || "");

    const handleApiKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLbctApiKey(e.target.value);
    };

    const handleScacChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLbctScac(e.target.value);
    };

    const handleUpdateCredentialFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const data = {
            userId: selectedUser?._id,
            api_key: lbctApiKey,
            scac: lbctScac,
        };

        try {
            const response = updateLbctCredentials(data);
            myDispatch(response);
            handleClose();
            await myDispatch(getAllUsers(userSearchPost, userSearchParams));
        } catch (error) {
            handleClose(); 
        }
    };

    return (
        <React.Fragment>
            {cLoading && <Loader />}
            <Modal show={showUpdateCredentialModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Update LBCT Credentials</Modal.Title>
                    <button className="btn btn-link" onClick={handleClose}>
                        <IconCancelCircle />
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={handleUpdateCredentialFormSubmit}>
                        <Form.Group className="mb-3" controlId="formLbctApiKey">
                            <FormLabel>Updating LBCT API Key for: {selectedUser?.email}</FormLabel>
                            <FormControl
                                type="text"
                                placeholder="Enter LBCT API Key"
                                value={lbctApiKey}
                                onChange={handleApiKeyChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formLbctScac">
                            <FormLabel>Updating LBCT SCAC for: {selectedUser?.email}</FormLabel>
                            <FormControl
                                type="text"
                                placeholder="Enter LBCT SCAC"
                                value={lbctScac}
                                onChange={handleScacChange}
                            />
                        </Form.Group>

                        <Modal.Footer>
                            <Button variant="secondary" type="button" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Update
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default UpdateLbctPop;