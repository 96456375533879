export const initialEmptyValues = {
  eLoading: false,
  empties: [],
  emErrMsg: null,
  terminals: [],
  emptiesData: [],
  isTomorrow: false,
  gatehour: [],
  emptiesLLMData: [],
};

export const emptyReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "GETEMPTYDATA_INPROGRESS": {
      return {
        ...state,
        eLoading: false,
        emErrMsg: null,
      };
    }
    case "GETEMPTYDATA_SUCCESS": {
      return {
        ...state,
        eLoading: true,
        emErrMsg: null,
        empties: action.payload.data,
        isTomorrow: action.payload.data.isTomorrow,
        // empties: emptiesData?.data,
        shiftCount: action.payload.data?.shiftCount,
      };
    }
    case "GETEMPTYDATA_REJECTED": {
      return {
        ...state,
        eLoading: true,
        emErrMsg: action.payload.data?.data,
        empties: [],
      };
    }
    case "SET_EMPTYDATA": {
      return {
        ...state,
        emptiesData: action.payload,
      };
    }
    case "FILTER_EMPTYDATA_INPROGRESS": {
      return {
        ...state,
        eLoading: false,
        emErrMsg: null,
      };
    }
    case "FILTER_EMPTYDATA_SUCCESS": {
      return {
        ...state,
        empties: action.payload?.data,
        eLoading: true,
        shiftCount: action.payload.data?.shiftCount,
      };
    }
    case "FILTER_EMPTYDATA_REJECTED": {
      return {
        ...state,
        eLoading: true,
        emErrMsg: action.payload.data?.data,
        empties: [],
      };
    }
    case "FETCH_GATEHOURS_INPROGRESS": {
      return {
        ...state,
        gLoading: true,
      };
    }
    case "FETCH_GATEHOURS_SUCCESS": {
      return {
        ...state,
        gLoading: false,
        gatehour: action.payload.data.data,
      };
    }
    case "FETCH_GATEHOURS_REJECTED": {
      return {
        ...state,
        gLoading: false,
        gatehour: [],
      };
    }
    case "EMPTY_CORRECTION_INPROGRESS": {
      // console.log(action.payload, "inprogress")
      return {
        ...state,

        ecErrMsg: null,
      };
    }
    case "EMPTY_CORRECTION_SUCCESS": {
      // console.log(action.payload, "sucess")

      return {
        ...state,
        emptiesCorrection: action.payload?.data,
      };
    }
    case "EMPTY_CORRECTION_REJECTED": {
      return {
        ...state,

        ecErrMsg: action.payload.data?.data,
        emptiesCorrection: [],
      };
    }
    case "EMPTY_LLM_DATA_INPROGRESS": {
      return {
        ...state,
        eLoading: true,
        ecErrMsg: null,
      };
    }
    case "EMPTY_LLM_DATA_SUCCESS": {
      return {
        ...state,
        eLoading: false,
        emptiesLLMData: action.payload?.data || [],
      };
    }
    case "EMPTY_LLM_DATA_REJECTED": {
      return {
        ...state,
        eLoading: false,
        emptiesLLMData: [],
        ecErrMsg: action.payload?.error || "Failed to fetch LLM Data",
      };
    }
    case "RESET_E_LOADING": {
      return {
        ...state,
        eLoading: true,
      };
    }
    default:
      return state;
  }
};
